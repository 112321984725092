// client/src/components/sessions/PendingRequestCard.js

import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';

const PendingRequestCard = ({
  request,
  userRole,
  onApprove,
  onReject
}) => {
  /**
   * request object shape (example):
   * {
   *   requestId,
   *   availabilityId,
   *   requestedStartTime: '2024-12-22T17:00:00Z',
   *   requestedEndTime: '2024-12-22T19:00:00Z',
   *   status: 'pending',
   *   Game: {
   *     gameId,
   *     gameName,
   *     image,
   *     ...
   *   }
   * }
   */

  const game = request.Game || {};
  const gameName = game.gameName || 'Untitled Game';
  const gameImage = game.image
    ? `/images/games/${game.image}`
    : '/images/games/default-cover.jpg';

  // Convert the startTime to user-friendly format, e.g. "On Friday at 14:00"
  let startStr = 'No start time.';
  if (request.requestedStartTime) {
    const start = dayjs(request.requestedStartTime);
    if (start.isValid()) {
      // e.g. "On Friday at 14:00"
      startStr = `On ${start.format('dddd')} at ${start.format('HH:mm')}`;
    }
  }

  return (
    <Card
      variant="sessionCard" // so it visually matches SessionCard (if you have a custom variant)      
    >
      {/* Top half: 100px image with radial vignette */}
      <Box sx={{ position: 'relative', height: '100px', overflow: 'hidden' }}>
        <CardMedia
          // If you're using a custom variant in theme, do variant="sessionCardMedia"
          component="img"
          image={gameImage}
          alt={gameName}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
        {/* Vignette overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
            background:
              'radial-gradient(closest-side, rgba(0,0,0,0.0), rgba(0,0,0,0.6))'
          }}
        />

        {/* Approve/Reject icons in the center of the image */}
        {userRole === 'dungeon_master' && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              gap: 1
            }}
          >
            <Tooltip title="Approve request">
              <IconButton
                color="success"
                size="small"
                sx={{
                  bgcolor: 'rgba(0,0,0,0.6)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' }
                }}
                onClick={() => onApprove(request.requestId)}
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject request">
              <IconButton
                color="error"
                size="small"
                sx={{
                  bgcolor: 'rgba(0,0,0,0.6)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' }
                }}
                onClick={() => onReject(request.requestId)}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Bottom half: game name + start time */}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{ color: 'text.primary', mb: 0.5 }}
        >
          {gameName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', lineHeight: 1.2 }}>
          {startStr}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PendingRequestCard;
