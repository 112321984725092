import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const PendingInvites = ({ invites, onAccept }) => {
  return (
    <div>
      <Typography variant="h5">Pending Invites</Typography>
      {invites.length > 0 ? (
        invites.map(invite => (
          <Box key={invite.inviteId} sx={{ display:'flex', alignItems:'center', gap:2, my:1 }}>
            <Typography>Game: {invite.gameName}</Typography>
            <Button variant="contained" onClick={() => onAccept(invite.inviteId)}>Accept</Button>
          </Box>
        ))
      ) : (
        <Typography>No pending invites</Typography>
      )}
    </div>
  );
};

export default PendingInvites;
