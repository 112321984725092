// client/src/pages/DMPage.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; 
import PendingRequestsList from '../components/sessions/PendingRequestsList';
import SessionOverview from '../components/sessions/SessionOverview';
import dayjs from 'dayjs';

import GameList from '../components/games/GameList';
import CreateGameForm from '../components/games/CreateGameForm';
import CalendarAvailability from '../components/games/CalendarAvailability';
// Assume we have a RecentSessionsList component:
import RecentSessionsList from '../components/sessions/RecentSessionsList';

const API_BASE = process.env.REACT_APP_API_BASE;

const DMPage = () => {
  const { user, token } = useContext(AuthContext);
  const [games, setGames] = useState([]);
  const [newGame, setNewGame] = useState({ gameName: '', gameSetting: '', gamingSoftware: '' });
  const [availabilities, setAvailabilities] = useState([]);

  const fetchGames = useCallback(async () => {
    if (!token) return;
    try {
      const res = await axios.get(`${API_BASE}/dm/games`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setGames(res.data);
    } catch (err) {
      console.error('Error fetching DM games:', err);
    }
  }, [token]);

  const fetchAvailabilities = useCallback(async () => {
    if (!token) return;
    try {
      const res = await axios.get(`${API_BASE}/dm/availability`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAvailabilities(res.data);
    } catch (err) {
      console.error('Error fetching availabilities:', err);
    }
  }, [token]);

  const handleCreateGame = async () => {
    if (!newGame.gameName) return console.log('Game Name is required');
    try {
      await axios.post(`${API_BASE}/dm/games`, newGame, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Game created!');
      setNewGame({ gameName: '', gameSetting: '', gamingSoftware: '' });
      fetchGames();
    } catch (err) {
      console.error('Error creating game:', err);
      console.log('Failed to create game');
    }
  };

  useEffect(() => {
    if (token && user && user.role === 'dungeon_master') {
      fetchGames();
      fetchAvailabilities();
    }
  }, [token, user, fetchGames, fetchAvailabilities]); 

  if (!user || user.role !== 'dungeon_master') {
    return <Typography>You must be a dungeon master to access this page.</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ bgcolor: 'background.default', color: 'text.primary', minHeight: '100vh' }}>        
        <Box sx={{ p:4 }}>
          {/* Use Grid for layout */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p:2 }}>               
                <SessionOverview />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p:2 }}>                
                <PendingRequestsList />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p:2 }}>
                <Typography variant="h5" gutterBottom>Calendar</Typography>
                <CalendarAvailability
                  token={token}
                  availabilities={availabilities}
                  fetchAvailabilities={fetchAvailabilities}
                  userRole={user.role}
                />
              </Paper>
            </Grid>
          </Grid>

          <Box sx={{ mt:4 }}>
            
            

            <Typography variant="h5" gutterBottom sx={{ mt:4 }}>Your Games</Typography>
            <GameList games={games} userRole={user.role} />
            <CreateGameForm newGame={newGame} setNewGame={setNewGame} onCreate={handleCreateGame} />
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DMPage;
