import React from 'react';
import { Typography, TextField, Button } from '@mui/material';

const LocationForm = ({ newLocationName, setNewLocationName, newLocationReason, setNewLocationReason, onCreate }) => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <Typography variant="h6">Create a Location</Typography>
      <TextField
        label="Location Name"
        value={newLocationName}
        onChange={e => setNewLocationName(e.target.value)}
        sx={{mr:2}}
      />
      <TextField
        label="Reason for Coming"
        value={newLocationReason}
        onChange={e => setNewLocationReason(e.target.value)}
        sx={{mr:2}}
      />
      <Button variant="contained" onClick={onCreate}>Create Location</Button>
    </div>
  );
};

export default LocationForm;
