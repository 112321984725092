import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    // Primary & secondary accents
    primary: { main: '#80c683' },     // Muted emerald/lime
    secondary: { main: '#f2a65a' },   // Soft orange
    background: {
      default: '#2a3b4c',  // Overall page background
      paper:   '#3d5268'   // Surfaces like Cards, Paper, etc.
    },
    text: {
      primary:   '#ffffff',
      secondary: '#cfcfcf'
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h2: {
      fontWeight: 'bold'
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#3d5268'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          // Example custom colors:
          backgroundColor: '#4c5e70',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#424f5d'
          }
        }
      }
    },
    // Custom variants for Card and CardMedia
    MuiCard: {
      variants: [
        {
          props: { variant: 'sessionCard' },
          style: {
            margin: '12px',
            backgroundColor: '#3B5060',
            width: 200,
            height: 200,
            display: 'flex',
            flexDirection: 'column',
            textDecoration: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.2s ease-out',
            borderRadius: 8,
            '&:hover': {
              backgroundColor: '#4C5E70'
            }
          }
        }
      ]
    },
    MuiCardMedia: {
      variants: [
        {
          props: { variant: 'sessionCardMedia' },
          style: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            // If you want a fallback background for missing images:
            backgroundColor: '#2a3b4c'
          }
        }
      ]
    },
  }
});

export default theme;
