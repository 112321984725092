// client/src/components/games/HeroBanner.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Avatar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // For "Generate AI" button

import { getAllCharactersInGame } from '../../utils/api';

const HeroBanner = ({
  image,
  title,
  subtitle,
  height = 300,
  overlayColor = 'rgba(0,0,0,0.3)',
  gameId,
  token,
  isDM = false,
  currentUserId,
  onChangeCover,
  onInvitePlayer,
  onCreateSession,
  onEditCharacter,
  onGenerateCover
}) => {
  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    if (gameId && token) {
      (async () => {
        try {
          const chars = await getAllCharactersInGame(token, gameId);
          setCharacters(chars);
        } catch (err) {
          console.error('Failed to fetch characters for game:', err);
        }
      })();
    }
  }, [gameId, token]);

  return (
    <Box
      sx={{
        maxWidth: 1000,
        margin: '0 auto',  // center horizontally
        position: 'relative',
        height,
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: image ? undefined : '#333'
      }}
    >
      {/* Background image */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          backgroundImage: image ? `url(${image})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
        }}
      />

      {/* Overlay */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          backgroundColor: overlayColor
        }}
      />

      {isDM && (
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'flex',
            gap: 1,
            zIndex: 2
          }}
        >
          {/* Change cover */}
          <Tooltip title="Change cover">
            <IconButton
              sx={{ bgcolor: 'rgba(0,0,0,0.4)', color: '#fff' }}
              onClick={onChangeCover}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          {/* Invite player */}
          <Tooltip title="Invite player">
            <IconButton
              sx={{ bgcolor: 'rgba(0,0,0,0.4)', color: '#fff' }}
              onClick={onInvitePlayer}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>

          {/* Create new session */}
          <Tooltip title="Create new session">
            <IconButton
              sx={{ bgcolor: 'rgba(0,0,0,0.4)', color: '#fff' }}
              onClick={onCreateSession}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>

          {/* Generate AI cover */}
          <Tooltip title="Generate AI cover">
            <IconButton
              sx={{ bgcolor: 'rgba(0,0,0,0.4)', color: '#fff' }}
              onClick={onGenerateCover}
            >
              <AutoFixHighIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {/* Title + subtitle (centered) */}
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '50%',
          left: 0,
          right: 0,
          transform: 'translateY(-50%)',
          textAlign: 'center',
          color: '#fff',
          px: 2
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            textShadow: '0 0 10px rgba(0,0,0,0.7)'
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="h5"
            sx={{ mt: 1, textShadow: '0 0 6px rgba(0,0,0,0.7)' }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>

      {/* Character Avatars */}
      {characters.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: 1,
            zIndex: 2
          }}
        >
          {characters.map(char => {
            const isOwner = (char.userId === currentUserId);
            const avatarUrl = char.avatar
              ? `/images/characters/${char.avatar}`
              : '/images/characters/default.png';

            return (
              <Box
                key={char.characterId}
                sx={{
                  position: 'relative',
                  // When hovering this Box, show the pencil
                  '&:hover .char-edit-btn': {
                    opacity: 1
                  }
                }}
              >
                <Tooltip title={char.name || char.race || 'Unnamed Character'}>
                  <Avatar
                    alt={char.name || 'Character'}
                    src={avatarUrl}
                    sx={{
                      width: 50,
                      height: 50,
                      border: '2px solid gold',
                      boxShadow: isOwner ? '0 0 8px 3px rgba(255,0,0,0.7)' : 'none',
                      cursor: isOwner ? 'pointer' : 'default'
                    }}
                  />
                </Tooltip>

                {/* Show pencil on hover if isOwner */}
                {isOwner && onEditCharacter && (
                  <IconButton
                  size="small"
                  className="char-edit-btn"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'rgba(0,0,0,0.5)',
                    color: '#fff',
                    opacity: 0, // start hidden
                    transition: 'opacity 0.2s ease-out',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' }
                  }}
                  onClick={() => onEditCharacter(char)}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

HeroBanner.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overlayColor: PropTypes.string,
  gameId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  token: PropTypes.string,
  isDM: PropTypes.bool,
  currentUserId: PropTypes.string,
  onChangeCover: PropTypes.func,
  onInvitePlayer: PropTypes.func,
  onCreateSession: PropTypes.func,
  onEditCharacter: PropTypes.func,
  onGenerateCover: PropTypes.func
};

export default HeroBanner;
