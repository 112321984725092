// client/src/components/scenes/SceneList.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import SceneItem from './SceneItem';

const SceneList = ({
  locationId,
  scenes,
  onToggleSceneFavorite,
  onUpdateScene,
  onDeleteScene,
  onCreateScene,
  allCharacters
}) => {
  const [newSceneSummary, setNewSceneSummary] = useState('');
  const [newSceneReason, setNewSceneReason] = useState('');

  const handleCreate = () => {
    if (!locationId) return;
    if (!newSceneSummary) {
      console.log('Scene summary is required');
      return;
    }
    onCreateScene(locationId, {
      summary: newSceneSummary,
      reason_for_coming: newSceneReason
    });
    setNewSceneSummary('');
    setNewSceneReason('');
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Scenes</Typography>
      {scenes && scenes.length > 0 ? (
        scenes.map((scene) => (
          <SceneItem
            key={scene.sceneId}
            scene={scene}
            onToggleFavorite={onToggleSceneFavorite}
            onUpdateScene={onUpdateScene}
            onDeleteScene={onDeleteScene}
            allCharacters={allCharacters} // pass to item
          />
        ))
      ) : (
        <Typography>No scenes yet.</Typography>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Create a Scene</Typography>
        <TextField
          label="Scene Summary"
          value={newSceneSummary}
          onChange={(e) => setNewSceneSummary(e.target.value)}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Reason for Coming"
          value={newSceneReason}
          onChange={(e) => setNewSceneReason(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleCreate}>Create Scene</Button>
      </Box>
    </Box>
  );
};

export default SceneList;
