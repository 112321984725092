// client/src/components/locations/LocationItem.js
import React, { useState } from 'react';
import { Card, CardContent, Box, Typography, Button, TextField, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SceneList from './../scenes/SceneList';

const LocationItem = ({
  location,
  scenes,
  expanded,
  onExpand,
  onToggleFavoriteLocation,
  onUpdateLocation,
  onDeleteLocation,
  onFetchScenes,
  onCreateScene,
  onToggleSceneFavorite,
  onUpdateScene,
  onDeleteScene,
  allCharacters // <-- new
}) => {
  const [editing, setEditing] = useState(false);
  const [locationName, setLocationName] = useState(location.locationName);
  const [locationReason, setLocationReason] = useState(location.reason_for_coming || '');

  const handleSave = () => {
    onUpdateLocation(location.locationId, {
      locationName,
      reason_for_coming: locationReason
    });
    setEditing(false);
  };

  const handleExpand = () => {
    onExpand(location.locationId);
    if (!expanded) {
      onFetchScenes(location.locationId);
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            {editing ? (
              <>
                <TextField
                  label="Location Name"
                  value={locationName}
                  onChange={(e) => setLocationName(e.target.value)}
                  sx={{ mr: 2 }}
                />
                <TextField
                  label="Reason"
                  value={locationReason}
                  onChange={(e) => setLocationReason(e.target.value)}
                  sx={{ mr: 2 }}
                />
                <Button variant="contained" onClick={handleSave}>Save</Button>
                <Button variant="text" onClick={() => setEditing(false)} sx={{ ml: 1 }}>Cancel</Button>
              </>
            ) : (
              <>
                <Typography variant="body1">{location.locationName}</Typography>
                <Typography variant="body2">
                  Reason: {location.reason_for_coming || 'N/A'}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => onToggleFavoriteLocation(location.locationId, location.favorite)}
                  sx={{ mr: 2, mt: 1 }}
                >
                  {location.favorite ? 'Unfavorite' : 'Favorite'}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setEditing(true)}
                  sx={{ mr: 2, mt: 1 }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => onDeleteLocation(location.locationId)}
                  sx={{ mt: 1 }}
                >
                  Delete
                </Button>
              </>
            )}
          </Box>
          <IconButton onClick={handleExpand}>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        {expanded && (
          <Box sx={{ mt: 2 }}>
            <SceneList
              locationId={location.locationId}
              scenes={scenes[location.locationId] || []}
              onCreateScene={onCreateScene}
              onToggleSceneFavorite={onToggleSceneFavorite}
              onUpdateScene={onUpdateScene}
              onDeleteScene={onDeleteScene}
              allCharacters={allCharacters} // pass further
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default LocationItem;
