// client/src/pages/PlayerDashboard.js
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PendingRequestsList from '../components/sessions/PendingRequestsList';

const API_BASE = process.env.REACT_APP_API_BASE;

const PlayerDashboard = () => {
  const { user, token, loading } = useContext(AuthContext);
  const [games, setGames] = useState([]);
  const navigate = useNavigate();

  const fetchPlayerGames = useCallback(async () => {
    if (!user || !token) return;
    try {
      // Adjust endpoint as needed; assuming GET /games/user/:userId
      const res = await axios.get(`${API_BASE}/games/user/${user.userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setGames(res.data);
    } catch (err) {
      console.error('Error fetching player games:', err);
    }
  }, [user, token]);

  useEffect(() => {
    if (!loading && user && token) {
      fetchPlayerGames();
    }
  }, [loading, user, token, fetchPlayerGames]);

  if (loading) return <Typography>Loading...</Typography>;
  if (!user) return <Typography>Please login first.</Typography>;

  return (
    <Box sx={{ p:4 }}>
      <Typography variant="h4" gutterBottom>Player Dashboard</Typography>

        {/* Show all pending requests for player's games */}
      <PendingRequestsList />

      {/* Show all pending requests for DM's games */}
      <PendingRequestsList />

      {games.length > 0 ? games.map(g => (
        <Box key={g.gameId} sx={{ my:2 }}>
          <Typography variant="body1">Game: {g.Game.gameName}</Typography>
          <Button variant="contained" onClick={() => navigate(`/player/game/${g.Game.gameId}`)}>
            View Game Calendar
          </Button>
        </Box>
      )) : (
        <Typography>No games found.</Typography>
      )}
    </Box>
  );
};

export default PlayerDashboard;
