// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD9-ZClWT1kAnAt8bmuSNK3W5GYKDSA0jI",
    authDomain: "tabletales-b4e05.firebaseapp.com",
    projectId: "tabletales-b4e05",
    storageBucket: "tabletales-b4e05.firebasestorage.app",
    messagingSenderId: "485564906508",
    appId: "1:485564906508:web:bf499674be26fd4e504229",
    measurementId: "G-EB294GY63X"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
