import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const GameList = ({ games, userRole }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>Your Games</Typography>
      {games.length > 0 ? (
        games.map(g => (
          <Box key={g.gameId} sx={{ mb:2, border:'1px solid #ccc', p:2 }}>
            <Typography variant="h6">{g.gameName}</Typography>
            <Typography variant="body1">Setting: {g.gameSetting || 'N/A'}</Typography>
            <Typography variant="body1">Software: {g.gamingSoftware || 'N/A'}</Typography>
            {userRole === 'dungeon_master' && (
              <Button component={Link} to={`/dm/game/${g.gameId}`}>
                Manage Game
              </Button>
            )}
          </Box>
        ))
      ) : (
        <Typography>No games yet.</Typography>
      )}
    </div>
  );
};

export default GameList;
