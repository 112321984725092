// client/src/components/sessions/PendingRequestsList.js
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { Box, Typography, Grid } from '@mui/material';
import PendingRequestCard from './PendingRequestCard'; // <-- import the new card
import dayjs from 'dayjs';

const API_BASE = process.env.REACT_APP_API_BASE;

const PendingRequestsList = ({ gameId, onDecision }) => {
  const { token, user } = useContext(AuthContext);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRequests = async () => {
    if (!token) return;
    setLoading(true);
    try {
      let url = `${API_BASE}/sessions/requests?status=pending`;
      if (gameId) {
        url += `&gameId=${gameId}`;
      }
      
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(res.data)
      setRequests(res.data);
    } catch (err) {
      console.error('Error fetching pending requests:', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRequests();
  }, [token, gameId]);

  // Approve/Reject
  const handleApprove = async (requestId) => {
    try {
      await axios.put(
        `${API_BASE}/sessions/requests/${requestId}/approve`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('Request approved');
      // re-fetch
      fetchRequests();
      // also refresh parent's data if needed
      if (onDecision) onDecision();
    } catch (err) {
      console.error('Error approving request:', err);
    }
  };

  const handleReject = async (requestId) => {
    try {
      await axios.put(
        `${API_BASE}/sessions/requests/${requestId}/reject`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('Request rejected');
      // re-fetch
      fetchRequests();
      // also refresh parent's data if needed
      if (onDecision) onDecision();
    } catch (err) {
      console.error('Error rejecting request:', err);
    }
  };

  if (!loading && requests.length === 0) {
    // Center the "No pending requests." text
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography>No pending requests.</Typography>
      </Box>
    );
  }

  return (
    <Box >
      <Typography variant="h5" gutterBottom>
        Pending Requests
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={2} sx={{ height: '100%' }}>
          {requests.map((req) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={req.requestId}>
              <PendingRequestCard
                request={req}
                userRole={user?.role}
                onApprove={handleApprove}
                onReject={handleReject}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PendingRequestsList;
