// client/src/components/scenes/SceneItem.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import CharacterHoverCard from '../characters/CharacterHoverCard';

function parseSummaryWithCharacters(summary, allCharacters) {
  console.log('[DEBUG] parseSummaryWithCharacters - Original summary:', summary);
  console.log('[DEBUG] parseSummaryWithCharacters - allCharacters:', allCharacters);

  if (!allCharacters || Object.keys(allCharacters).length === 0) {
    console.log('[DEBUG] No characters found; returning plain summary');
    return summary;
  }

  let result = [summary];

  Object.keys(allCharacters).forEach((charName) => {
    console.log(`[DEBUG] Now searching for charName="${charName}" using regex.`);
    const re = new RegExp(`(^|\\P{L})(${charName}\\p{L}*)`, 'gu');
    console.log('[DEBUG] Regex source:', re);

    // For each piece of text in "result"
    result = result.flatMap((fragment) => {
      if (typeof fragment !== 'string') {
        console.log('[DEBUG] Fragment is not a string, returning as-is:', fragment);
        return [fragment];
      }

      console.log(`[DEBUG] Checking fragment: "${fragment}"`);
      const newPieces = [];
      let lastIndex = 0;
      let match;

      // reset lastIndex for each new string fragment
      re.lastIndex = 0;

      while ((match = re.exec(fragment)) !== null) {
        /*
          match[0] = boundary + charName
          match[1] = boundary (space, punctuation, or start)
          match[2] = charName
          match.index => start of boundary+charName
          re.lastIndex => end of boundary+charName
        */
        console.log('[DEBUG] MATCH FOUND:', {
          match0: match[0],
          match1: match[1],
          match2: match[2],
          index: match.index,
          lastIndex: re.lastIndex
        });

        // Everything before this match
        const beforeMatch = fragment.slice(lastIndex, match.index);
        if (beforeMatch) {
          console.log('[DEBUG]  -- text before match:', beforeMatch);
          newPieces.push(beforeMatch);
        }

        // Insert boundary as plain text
        if (match[1]) {
          console.log('[DEBUG]  -- boundary:', match[1]);
          newPieces.push(match[1]);
        }

        // Insert HoverCard for the actual name
        const realName = match[2];
        const charData = allCharacters[charName];
        console.log('[DEBUG]  -- name for hover card:', realName);
        newPieces.push(
          <CharacterHoverCard
            key={`hover-${charName}-${match.index}-${Math.random()}`}
            characterName={realName}
            characterData={charData}
          >
            {realName}
          </CharacterHoverCard>
        );

        lastIndex = re.lastIndex;
      }

      // Anything left after the last match
      const remainder = fragment.slice(lastIndex);
      if (remainder) {
        console.log('[DEBUG]  -- remainder:', remainder);
        newPieces.push(remainder);
      }

      console.log('[DEBUG] newPieces after searching charName:', newPieces);
      return newPieces;
    });
  });

  console.log('[DEBUG] Final result:', result);
  return result;
}

const SceneItem = ({
  scene,
  onToggleFavorite,
  onUpdateScene,
  onDeleteScene,
  allCharacters
}) => {
  const [editing, setEditing] = useState(false);
  const [summary, setSummary] = useState(scene.summary || '');
  const [reason, setReason] = useState(scene.reason_for_coming || '');

  const encounteredNpcs = scene.unstructured_data?.encountered_npcs || [];

  const handleSave = () => {
    onUpdateScene(scene.sceneId, { summary, reason_for_coming: reason });
    setEditing(false);
  };

  const renderedSummary = parseSummaryWithCharacters(summary, allCharacters);

  return (
    <Box sx={{ border: '1px solid #ccc', p: 2, my: 2 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ flex: 3 }}>
          {editing ? (
            <>
              <TextField
                label="Scene Summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                sx={{ mr: 2 }}
              />
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
              <Button variant="text" onClick={() => setEditing(false)} sx={{ ml: 1 }}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Typography 
                variant="body1" 
                component="div"  // <--- important to avoid <div> inside <p>
                sx={{ fontWeight: 'bold' }}
              >
                {renderedSummary}
              </Typography>
              <Typography variant="body2">
                Reason: {scene.reason_for_coming || 'N/A'}
              </Typography>
              <Button
                variant="contained"
                onClick={() => onToggleFavorite(scene.sceneId, scene.favorite)}
                sx={{ mr: 2, mt: 1 }}
              >
                {scene.favorite ? 'Unfavorite' : 'Favorite'}
              </Button>
              <Button
                variant="contained"
                onClick={() => setEditing(true)}
                sx={{ mr: 2, mt: 1 }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => onDeleteScene(scene.sceneId)}
                sx={{ mt: 1 }}
              >
                Delete
              </Button>
            </>
          )}
        </Box>

        <Box sx={{ flex: 1, borderLeft: '1px solid #ccc', pl: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            NPCs Encountered
          </Typography>
          {encounteredNpcs.length > 0 ? (
            <List dense>
              {encounteredNpcs.map((npc, idx) => (
                <React.Fragment key={idx}>
                  <ListItem>
                    <ListItemText primary={npc} />
                  </ListItem>
                  {idx < encounteredNpcs.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography variant="body2">No NPCs recorded.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SceneItem;
