// client/src/components/sessions/SessionCard.js
import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Tooltip
} from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const SessionCard = ({ session, mode = 'planned' }) => {
  const { Game } = session;
  const gameName = Game?.gameName || 'Untitled Game';
  const gameImage = Game?.image
    ? `/images/games/${Game.image}`
    : '/images/games/default-cover.jpg';

  // Decide how to show startTime or endTime
  let timeInfo = 'No start time.';
  if (session.startTime) {
    const start = dayjs(session.startTime);
    if (start.isValid()) {
      if (start.isAfter(dayjs())) {
        timeInfo = `Starts ${start.fromNow()}`;
      } else {
        timeInfo = `Started ${start.fromNow()}`;
      }
    }
  }
  if (mode === 'recent' && session.endTime) {
    const end = dayjs(session.endTime);
    if (end.isValid()) {
      timeInfo = `Ended ${end.fromNow()}`;
    }
  }

  const sessionLink = `/game/${Game?.gameId || session.gameId}/session/${session.sessionId}`;

  return (
    <Tooltip title={session.name || 'No session name'}>
      <Card
        // 1) Use custom variant from theme
        variant="sessionCard"
        component={Link}
        to={sessionLink}
      >
        {/* Top half: the image with a radial vignette */}
        <Box sx={{ position: 'relative', height: '100px', overflow: 'hidden' }}>
          <CardMedia
            // 2) Use custom variant from theme
            variant="sessionCardMedia"
            image={gameImage}
            alt={gameName}
          />
          {/* Vignette overlay */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
              background:
                'radial-gradient(closest-side, rgba(0,0,0,0.0), rgba(0,0,0,0.6))'
            }}
          />
        </Box>

        {/* Bottom half: text content */}
        <CardContent sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', justifyContent: 'center' }}>
          <Typography variant="subtitle2" fontWeight="bold" sx={{ color: 'text.primary', mb: 0.5 }}>
            {gameName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', lineHeight: 1.2 }}>
            {timeInfo}
          </Typography>
        </CardContent>
      </Card>
    </Tooltip>
  );
};

export default SessionCard;
