// client/src/pages/Account.js
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getUser, updateUser, getCharacter, updateCharacter, getGames } from '../utils/api';
import { Box, Typography, TextField, Button, Tabs, Tab } from '@mui/material';
import axios from 'axios';
import PendingInvites from '../components/invites/PendingInvites';

const API_BASE = process.env.REACT_APP_API_BASE;

const Account = () => {
  const { user, token, loading } = useContext(AuthContext);

  const [tab, setTab] = useState(0);
  const [userData, setUserData] = useState({ email: '', discordId: '' });
  const [characterData, setCharacterData] = useState({ race: '', age: '', description: '', history: '' });
  const [games, setGames] = useState([]);
  const [invites, setInvites] = useState([]);

  // Wrap loadData in useCallback so it doesn't change identity every render
  const loadData = useCallback(async () => {
    if (!token || !user) return;
    try {
      // Fetch user from API
      const u = await getUser(token, user.userId);
      let apiEmail = u.email || user.email || '';
      setUserData({
        email: apiEmail,
        discordId: u.discordId || ''
      });

      // Fetch character
      try {
        const c = await getCharacter(token, user.userId);
        setCharacterData({
          race: c.race || '',
          age: c.age ? String(c.age) : '',
          description: c.description || '',
          history: c.history || ''
        });
      } catch (charErr) {
        console.warn('No character found, user can create one.');
      }

      // Fetch games
      const g = await getGames(token, user.userId);
      const userGames = g.map(gp => gp.Game);
      setGames(userGames);
    } catch (err) {
      console.error('Error loading data:', err);
    }
  }, [token, user]);

  const handleUserSave = async () => {
    try {
      const updated = await updateUser(token, user.userId, userData);
      setUserData({
        email: updated.email || userData.email,
        discordId: updated.discordId || userData.discordId
      });
      console.log('User updated successfully');
    } catch (err) {
      console.error('Error updating user:', err);
      console.log('Failed to update user');
    }
  };

  const handleCharacterSave = async () => {
    try {
      const updatedChar = await updateCharacter(token, user.userId, characterData);
      setCharacterData({
        race: updatedChar.race || characterData.race,
        age: updatedChar.age ? String(updatedChar.age) : characterData.age,
        description: updatedChar.description || characterData.description,
        history: updatedChar.history || characterData.history
      });
      console.log('Character updated successfully');
    } catch (err) {
      console.error('Error updating character:', err);
      console.log('Failed to update character');
    }
  };

  // Wrap fetchInvites in useCallback as well
  const fetchInvites = useCallback(async () => {
    if (!token || !user) return;
    const res = await axios.get(`${API_BASE}/invites/me?status=pending`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setInvites(res.data);
  }, [token, user]);

  const handleAccept = async (inviteId) => {
    await axios.put(`${API_BASE}/invites/${inviteId}/accept`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Invite accepted!');
    fetchInvites();
  };

  useEffect(() => {
    if (!loading && token && user) {
      loadData();
    }
  }, [loading, token, user, loadData]); // Now loadData is included

  useEffect(() => {
    if (user && token) {
      fetchInvites();
    }
  }, [user, token, fetchInvites]); // Now fetchInvites is included

  if (loading) return <Typography>Loading...</Typography>;
  if (!user) return <Typography>Please login first.</Typography>;

  return (
    <Box sx={{ p:4 }}>
      <Typography variant="h4" gutterBottom>Account Dashboard</Typography>

      <PendingInvites invites={invites} onAccept={handleAccept} />

      <Tabs value={tab} onChange={(e, v) => setTab(v)} sx={{ mb:2 }}>
        <Tab label="Profile" />
        <Tab label="Character" />
        <Tab label="Active Games" />
      </Tabs>

      {tab === 0 && (
        <Box>
          <TextField
            label="Email"
            value={userData.email}
            sx={{ mr:2 }}
            disabled
            onChange={e => setUserData({ ...userData, email: e.target.value })}
          />
          <TextField
            label="Discord ID"
            value={userData.discordId}
            onChange={e => setUserData({ ...userData, discordId: e.target.value })}
          />
          <Box sx={{ mt:2 }}>
            <Button variant="contained" onClick={handleUserSave}>Save Profile</Button>
          </Box>
        </Box>
      )}

      {tab === 1 && (
        <Box>
          <TextField
            label="Race"
            value={characterData.race}
            onChange={e => setCharacterData({ ...characterData, race: e.target.value })}
            sx={{ mr:2 }}
          />
          <TextField
            label="Age"
            type="number"
            value={characterData.age}
            onChange={e => setCharacterData({ ...characterData, age: e.target.value })}
            sx={{ mr:2 }}
          />
          <TextField
            label="Description"
            value={characterData.description}
            multiline
            onChange={e => setCharacterData({ ...characterData, description: e.target.value })}
            sx={{ display: 'block', my:2, width:'100%' }}
          />
          <TextField
            label="History"
            value={characterData.history}
            multiline
            onChange={e => setCharacterData({ ...characterData, history: e.target.value })}
            sx={{ display: 'block', my:2, width:'100%' }}
          />
          <Box sx={{ mt:2 }}>
            <Button variant="contained" onClick={handleCharacterSave}>Save Character</Button>
          </Box>
        </Box>
      )}

      {tab === 2 && (
        <Box>
          <Typography variant="h6">Your Games</Typography>
          {games && games.length > 0 ? (
            games.map(g => (
              <Box key={g.gameId} sx={{ my:2 }}>
                <Typography variant="body1">Game: {g.gameName}</Typography>
              </Box>
            ))
          ) : <Typography>No games found.</Typography>}
        </Box>
      )}
    </Box>
  );
};

export default Account;
