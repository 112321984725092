// client/src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { onIdTokenChanged } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';

export const AuthContext = createContext({
  user: null,
  token: null,
  loading: true,
  errorMessage: null
});

const API_BASE = process.env.REACT_APP_API_BASE;

export const AuthProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [token, setToken] = useState(null);
  const [appUser, setAppUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const ensureAppUser = async (fUser, t) => {
    if (!fUser) {
      setAppUser(null);
      return;
    }

    try {
      const userData = {
        email: fUser.email,
        displayName: fUser.displayName || ''
      };

      const createRes = await axios.post(`${API_BASE}/users`, userData, {
        headers: { Authorization: `Bearer ${t}` }
      });
      setAppUser(createRes.data);
      setErrorMessage(null);
      console.log('User ensured successfully');
    } catch (err) {
      console.error('Failed to ensure user:', err);
      setErrorMessage('Failed to create or update user. Please try again later.');
    }
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (fUser) => {
      if (fUser) {
        const t = await fUser.getIdToken();
        setFirebaseUser(fUser);
        setToken(t);
        await ensureAppUser(fUser, t);
      } else {
        setFirebaseUser(null);
        setToken(null);
        setAppUser(null);
        setErrorMessage(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user: appUser, token, loading, errorMessage }}>
      {children}
    </AuthContext.Provider>
  );
};
