// client/src/pages/Login.js
import React, { useContext, useEffect } from 'react';
import { auth, googleProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { user, loading, errorMessage } = useContext(AuthContext);

  useEffect(() => {
    // If user is already logged in, redirect to /account
    if (!loading && user && user.userId) {
      navigate('/account');
    }
  }, [loading, user, navigate]);

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      // AuthContext will handle user creation.
      // Once user is logged in, AuthContext updates appUser and we redirect on useEffect.
    } catch (err) {
      console.error('Login error:', err);
      // No alert, just console logging. The AuthContext may set errorMessage if creation fails.
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  // If not logged in (user is null or userId missing), show login button and any errorMessage
  return (
    <Box sx={{ p:4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>Login</Typography>
      {errorMessage && (
        <Typography color="error" gutterBottom>{errorMessage}</Typography>
      )}
      <Button variant="contained" onClick={handleGoogleLogin}>
        Sign in with Google
      </Button>
    </Box>
  );
};

export default Login;
