// client/src/components/characters/cropUtils.js
// from https://codesandbox.io/s/react-easy-crop-demo-with-cropped-output-q9l8jqr5qw

export const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-domain issues
      image.src = url;
    });
  
  export async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    // ensure canvas has the correct dimensions
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
  
    ctx.drawImage(
      image,
      pixelCrop.x, pixelCrop.y,
      pixelCrop.width, pixelCrop.height,
      0, 0,
      pixelCrop.width, pixelCrop.height
    );
  
    // convert to blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (!file) {
          resolve(null);
          return;
        }
        // rename the file, e.g. "cropped.jpeg"
        const newFile = new File([file], 'cropped.jpeg', { type: file.type });
        resolve(newFile);
      }, 'image/jpeg', 0.95);
    });
  }
  