// src/pages/Home.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom>Welcome to TableTales</Typography>
      <Typography variant="h5" gutterBottom>Your gateway to D&D sessions</Typography>
      <Button component={Link} to="/login" variant="contained" color="primary">
        Login with your account
      </Button>
    </Box>
  );
};

export default Home;
