import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';

import HeroBanner from '../components/games/HeroBanner';
import FavoritesList from '../components/games/FavoritesList';
import SessionList from '../components/sessions/SessionList';
import GenerateCoverDialog from '../components/games/GenerateCoverDialog';

const API_BASE = process.env.REACT_APP_API_BASE;

const DMGamePage = () => {
  const { token, user } = useContext(AuthContext);
  const { gameId } = useParams();

  const [game, setGame] = useState(null);
  const [error, setError] = useState(null);

  // Cover upload
  const [imageFile, setImageFile] = useState(null);

  // DM invites
  const [inviteOpen, setInviteOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  // DM create session
  const [createSessionOpen, setCreateSessionOpen] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');

  // Sessions & favorites
  const [sessions, setSessions] = useState([]);
  const [favorites, setFavorites] = useState({ locations: [], scenes: [] });

  // Generate AI cover dialog
  const [coverDialogOpen, setCoverDialogOpen] = useState(false);

  // ─────────────────────────────────────────────────────
  // Fetch game details, sessions, and favorites
  // ─────────────────────────────────────────────────────
  useEffect(() => {
    if (token && user && user.role === 'dungeon_master') {
      fetchGame();
      fetchSessions();
      fetchFavorites();
    }
  }, [token, user, gameId]);

  const fetchGame = async () => {
    try {
      const res = await axios.get(`${API_BASE}/games/${gameId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setGame(res.data);
    } catch (err) {
      console.error('Error fetching game:', err);
      setError('Failed to load game details.');
    }
  };

  const fetchSessions = async () => {
    try {
      const res = await axios.get(`${API_BASE}/games/${gameId}/sessions`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSessions(res.data);
    } catch (err) {
      console.error('Error fetching sessions:', err);
      setError('Failed to load sessions.');
    }
  };

  const fetchFavorites = async () => {
    try {
      const res = await axios.get(`${API_BASE}/games/${gameId}/favorites`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFavorites(res.data);
    } catch (err) {
      console.error('Error fetching favorites:', err);
      setError('Failed to load favorites.');
    }
  };

  // ─────────────────────────────────────────────────────
  // DM-only actions
  // ─────────────────────────────────────────────────────
  // 1) Upload cover
  const handleImageUpload = async () => {
    if (!imageFile) return console.log('No file selected');
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      await axios.post(`${API_BASE}/games/${gameId}/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Image uploaded!');
      fetchGame(); // re-fetch
      setImageFile(null);
    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Failed to upload image.');
    }
  };

  // 2) Invite player
  const handleInvitePlayer = async () => {
    if (!inviteEmail) {
      console.log('Email is required');
      return;
    }
    try {
      await axios.post(`${API_BASE}/games/${gameId}/invite`, { email: inviteEmail }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Invite sent!');
      setInviteEmail('');
      setInviteOpen(false);
    } catch (err) {
      console.error('Error sending invite:', err);
      setError('Failed to send invite.');
    }
  };

  // 3) Create new session
  const handleCreateSession = async () => {
    if (!newSessionName) {
      console.log('Session name is required');
      return;
    }
    try {
      await axios.post(`${API_BASE}/games/${gameId}/sessions`, { name: newSessionName }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Session created!');
      setNewSessionName('');
      setCreateSessionOpen(false);
      fetchSessions();
    } catch (err) {
      console.error('Error creating session:', err);
      setError('Failed to create session.');
    }
  };

  // ─────────────────────────────────────────────────────
  // HeroBanner button callbacks
  // ─────────────────────────────────────────────────────
  const handleChangeCover = () => {
    // open hidden file input
    const fileInput = document.getElementById('game-cover-input');
    if (fileInput) fileInput.click();
  };

  const handleOpenInviteDialog = () => {
    setInviteOpen(true);
  };

  const handleOpenCreateSessionDialog = () => {
    setCreateSessionOpen(true);
  };

  // ─────────────────────────────────────────────────────
  //  Render
  // ─────────────────────────────────────────────────────
  if (!user || user.role !== 'dungeon_master') {
    return <Typography>You must be a dungeon master to access this page.</Typography>;
  }
  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error" variant="h6">{error}</Typography>
      </Box>
    );
  }
  if (!game) {
    return <Typography>Loading game details...</Typography>;
  }

  // Build final cover image path
  const coverImage = game.image
    ? `/images/games/${game.image}`
    : '/images/games/default-cover.jpg';

  return (
    <Box>
      {/* Hero Banner */}
      <HeroBanner
        image={coverImage}
        title={game.gameName}
        subtitle={`${game.gameSetting || 'N/A'} | ${game.gamingSoftware || 'N/A'}`}
        height={300}
        overlayColor="rgba(0,0,0,0.5)"
        gameId={gameId}
        token={token}
        isDM
        onChangeCover={handleChangeCover}
        onInvitePlayer={handleOpenInviteDialog}
        onCreateSession={handleOpenCreateSessionDialog}
        onGenerateCover={() => setCoverDialogOpen(true)} // <-- new callback
      />

      {/* Hidden file input for the cover */}
      <input
        id="game-cover-input"
        type="file"
        style={{ display: 'none' }}
        onChange={e => setImageFile(e.target.files?.[0] ?? null)}
      />
      {imageFile && (
        <Box sx={{ p:2 }}>
          <Button variant="contained" onClick={handleImageUpload}>
            Upload Cover
          </Button>
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ p:4 }}>
        {/* Sessions */}
        <Box sx={{ mb:4 }}>
          <Typography variant="h5" gutterBottom>Sessions</Typography>
          <SessionList gameId={gameId} sessions={sessions} />
        </Box>

        {/* Favorites */}
        <Box sx={{ mb:4 }}>
          <Typography variant="h5" gutterBottom>Favorites</Typography>
          <FavoritesList favorites={favorites} />
        </Box>
      </Box>

      {/* Invite Dialog */}
      <Dialog open={inviteOpen} onClose={() => setInviteOpen(false)}>
        <DialogTitle>Invite a Player</DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Player Email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleInvitePlayer}>
            Invite
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Session Dialog */}
      <Dialog open={createSessionOpen} onClose={() => setCreateSessionOpen(false)}>
        <DialogTitle>Create a New Session</DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Session Name"
            value={newSessionName}
            onChange={(e) => setNewSessionName(e.target.value)}
            fullWidth
            sx={{ mt:2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateSessionOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCreateSession}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <>
      

      <GenerateCoverDialog
        open={coverDialogOpen}
        onClose={() => setCoverDialogOpen(false)}
        token={token}
        gameId={gameId}
        onCoverSaved={(newImg) => {
          // 1) We can re-fetch the game so the updated cover is displayed.
          fetchGame();
          // 2) Possibly show a toast or do something else
          console.log('Cover saved to: ', newImg);
        }}
      />
      </>
    </Box>
  );
};

export default DMGamePage;
