import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
  Avatar,
  IconButton,
  CircularProgress
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';

import {
  getAllCharactersInGame,
  updateCharacter,
  generateGameCover,
  uploadCoverForGame
} from '../../utils/api';

/**
 * Props:
 *   open          : bool   - controls dialog visibility
 *   onClose       : func   - called to close popup
 *   token         : string - auth token
 *   gameId        : number - which game to operate on
 *   onCoverSaved  : func   - optional callback with (imgUrl) after saving
 * 
 * This version:
 *   - Supports a single image or multiple images from the AI
 *   - Show an error from the backend (like { "error": "No AI generations left." })
 *   - Provide "Regenerate" if we want multiple tries
 *   - The image is displayed full-width if only one is returned
 *   - On success (click to use), we update the game cover and call onCoverSaved
 */

function GenerateCoverDialog({
  open,
  onClose,
  gameId,
  token,
  onCoverSaved // optional callback
}) {
  // Characters & text edits
  const [loadingChars, setLoadingChars] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [editingCharId, setEditingCharId] = useState(null);
  const [charEdits, setCharEdits] = useState({}); // { charId: { visualDescription: "..."}}

  // Prompt
  const [dmPrompt, setDmPrompt] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  // Generation
  const [loadingGen, setLoadingGen] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]); // e.g. [ 'url1', 'url2', ... ] or just [ 'url' ]
  const [aiGensLeft, setAiGensLeft] = useState(null); // if server returns how many times left

  // ─────────────────────────────────────────────────────────────────────────
  // 1) Fetch characters once the dialog is opened
  // ─────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (open && gameId && token) {
      fetchCharacters();
    }
  }, [open, gameId, token]);

  const fetchCharacters = async () => {
    setLoadingChars(true);
    setErrorMessage(null);
    try {
      const chars = await getAllCharactersInGame(token, gameId);
      setCharacters(chars);

      const initialEdits = {};
      chars.forEach((c) => {
        initialEdits[c.characterId] = {
          visualDescription: c.visualDescription || ''
        };
      });
      setCharEdits(initialEdits);
    } catch (err) {
      console.error('Error fetching characters:', err);
      setErrorMessage('Failed to load characters.');
    }
    setLoadingChars(false);
  };

  // ─────────────────────────────────────────────────────────────────────────
  // 2) Editing & Saving character descriptions
  // ─────────────────────────────────────────────────────────────────────────
  const handleCharDescChange = (charId, newVal) => {
    setCharEdits((prev) => ({
      ...prev,
      [charId]: {
        ...prev[charId],
        visualDescription: newVal
      }
    }));
  };

  const handleSaveCharDesc = async (charId) => {
    const newDesc = charEdits[charId]?.visualDescription || '';
    try {
      await updateCharacter(token, charId, { visualDescription: newDesc });
      console.log(`Character ${charId} updated!`);
    } catch (err) {
      console.error(`Failed to save char ${charId}:`, err);
      setErrorMessage('Failed to save character description');
    }
    setEditingCharId(null);
  };

  // ─────────────────────────────────────────────────────────────────────────
  // 3) Generate a cover
  // ─────────────────────────────────────────────────────────────────────────
  const handleGenerate = async () => {
    if (!dmPrompt) {
      setErrorMessage('Please enter a prompt');
      return;
    }
    setLoadingGen(true);
    setErrorMessage(null);
    try {
      const result = await generateGameCover(token, gameId, dmPrompt);
      // The backend might return:
      // { success: true, images: [ { url: "..."} ], message: "...", ai_gens_left: 3 }
      // We'll parse images => string array
      const imgArr = (result.images || []).map((imgObj) => imgObj.url);
      setGeneratedImages(imgArr);

      // If your backend passes ai_gens_left
      if (result.ai_gens_left != null) {
        setAiGensLeft(result.ai_gens_left);
      }
    } catch (err) {
      console.error('Error generating cover:', err);
      // If the server returns JSON with { "error": "No AI generations left." }, we can handle that:
      if (err.response?.data?.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage('Failed to generate cover');
      }
    }
    setLoadingGen(false);
  };

  // ─────────────────────────────────────────────────────────────────────────
  // 4) If the user chooses a generated cover => upload
  // ─────────────────────────────────────────────────────────────────────────
  const handleChooseCover = async (imgUrl) => {
    setErrorMessage(null);
    try {
      await uploadCoverForGame(token, gameId, imgUrl);
      console.log('Cover updated to', imgUrl);
      // If the parent wants to do something after success:
      if (onCoverSaved) onCoverSaved(imgUrl);
      // Close automatically
      onClose();
    } catch (err) {
      console.error('Error uploading cover:', err);
      setErrorMessage('Failed to save cover');
    }
  };

  // ─────────────────────────────────────────────────────────────────────────
  // 5) Re-generate (same as handleGenerate)
  // ─────────────────────────────────────────────────────────────────────────
  const handleRegenerate = () => {
    setGeneratedImages([]);
    handleGenerate();
  };

  // ─────────────────────────────────────────────────────────────────────────
  //  Render
  // ─────────────────────────────────────────────────────────────────────────
  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Generate a New Game Cover</DialogTitle>

      <DialogContent dividers>
        {/* Show any error message from the server */}
        {errorMessage && (
          <Typography color="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}

        {/* Show how many gens left if known */}
        {aiGensLeft !== null && (
          <Typography sx={{ mb: 2 }}>
            AI generations left: {aiGensLeft}
          </Typography>
        )}

        {/* If characters are still loading */}
        {loadingChars ? (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Characters in this Game
            </Typography>
            {characters.map((char) => {
              const isEditing = editingCharId === char.characterId;
              const descValue =
                charEdits[char.characterId]?.visualDescription || '';
              const avatarUrl = char.avatar
                ? `/images/characters/${char.avatar}`
                : '/images/characters/default.png';

              return (
                <Box
                  key={char.characterId}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    p: 1,
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    borderRadius: '4px'
                  }}
                >
                  <Avatar
                    src={avatarUrl}
                    alt={char.race || 'Character'}
                    sx={{
                      width: 50,
                      height: 50,
                      border: '2px solid gold',
                      mr: 2
                    }}
                  />
                  <Box sx={{ flex: '1 1 auto' }}>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        value={descValue}
                        onChange={(e) =>
                          handleCharDescChange(char.characterId, e.target.value)
                        }
                      />
                    ) : (
                      <Typography sx={{ color: '#ccc' }}>
                        {descValue || <i>No description</i>}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ ml: 2 }}>
                    {isEditing ? (
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveCharDesc(char.characterId)}
                      >
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => setEditingCharId(char.characterId)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        {/* DM prompt input */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Your AI Prompt</Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={dmPrompt}
            onChange={(e) => setDmPrompt(e.target.value)}
            placeholder="Ex: Epic fantasy cover with a hidden forest and castle towers..."
          />
          <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
            <Button
              variant="contained"
              disabled={loadingGen || !dmPrompt || (aiGensLeft === 0)}
              onClick={handleGenerate}
            >
              {loadingGen ? 'Generating...' : 'Generate Cover'}
            </Button>
            {generatedImages.length > 0 && (
              <Button
                variant="outlined"
                disabled={loadingGen || (aiGensLeft === 0)}
                onClick={handleRegenerate}
              >
                Regenerate
              </Button>
            )}
          </Box>
        </Box>

        {/* If we have at least one image from AI, show it (assume 1 for now) */}
        {generatedImages.length > 0 && (
          <Box sx={{ mt: 2 }}>
            {/* If you only get 1 image from the server, do: */}
            {/* Full width image preview */}
            <Box sx={{ width: '100%', height: 'auto', position: 'relative' }}>
              <img
                src={generatedImages[0]}
                alt="AI cover preview"
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  borderRadius: 4
                }}
                onClick={() => handleChooseCover(generatedImages[0])}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  color: '#fff',
                  px: 2,
                  py: 1,
                  borderRadius: 4,
                  cursor: 'pointer'
                }}
                onClick={() => handleChooseCover(generatedImages[0])}
              >
                Use this Cover
              </Box>
            </Box>

            {/* 
              If your server returns more images, you might map them:
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                {generatedImages.map((imgUrl, idx) => (
                  <Box
                    key={idx}
                    sx={{ 
                      position: 'relative', width: 'calc(100% / 2 - 8px)', 
                      // or your layout of images 
                    }}
                    onClick={() => handleChooseCover(imgUrl)}
                  >
                    <img src={imgUrl} style={{ width: '100%', borderRadius: 4 }} />
                    <Box>...some overlay text...</Box>
                  </Box>
                ))}
              </Box>
            */}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateCoverDialog;
