// client/src/components/DMRoute.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const DMRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/login" />;

  // Assuming user role is stored as user.customClaims or fetched separately.
  // If user role not stored in user object directly, fetch user details from API 
  // and store in context before rendering this route.
  
  // For now, assume `user` object has `role`.
  // If not, you will need to fetch user details in AuthContext or DMRoute.
  const role = user.role || 'player'; // fallback
  if (role !== 'dungeon_master') return <Navigate to="/" />;

  return children;
};

export default DMRoute;
