// client/src/components/locations/LocationList.js
import React from 'react';
import { Typography } from '@mui/material';
import LocationItem from './LocationItem';

const LocationList = ({
  locations,
  expandedLocations,
  scenes,
  onExpandLocation,
  onToggleFavoriteLocation,
  onFetchScenes,
  onCreateScene,
  onToggleSceneFavorite,
  onUpdateScene,
  onDeleteScene,
  onUpdateLocation,
  onDeleteLocation,
  allCharacters // <-- new
}) => {
  return (
    <>
      <Typography variant="h5">Locations</Typography>
      {locations.length > 0 ? (
        locations.map((loc) => (
          <LocationItem
            key={loc.locationId}
            location={loc}
            scenes={scenes}
            expanded={!!expandedLocations[loc.locationId]}
            onExpand={onExpandLocation}
            onToggleFavoriteLocation={onToggleFavoriteLocation}
            onFetchScenes={onFetchScenes}
            onCreateScene={onCreateScene}
            onToggleSceneFavorite={onToggleSceneFavorite}
            onUpdateScene={onUpdateScene}
            onDeleteScene={onDeleteScene}
            onUpdateLocation={onUpdateLocation}
            onDeleteLocation={onDeleteLocation}
            allCharacters={allCharacters} // pass down
          />
        ))
      ) : (
        <Typography>No locations yet.</Typography>
      )}
    </>
  );
};

export default LocationList;
