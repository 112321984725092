// src/pages/Calendar.js
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { requestSession } from '../utils/api';
import { Box, Typography, TextField, Button } from '@mui/material';

const CalendarPage = () => {
  const { token, user } = useContext(AuthContext);
  const [date, setDate] = useState('');

  const handleRequest = async () => {
    if (!date) return;
    try {
      await requestSession(token, date);
      console.log('Session requested!');
    } catch (err) {
      console.error(err);
      console.log('Error requesting session');
    }
  };

  if (!user) return <Typography>Please login first</Typography>;

  return (
    <Box sx={{ p:4 }}>
      <Typography variant="h4" gutterBottom>Request a Session</Typography>
      <TextField
        type="date"
        value={date}
        onChange={e => setDate(e.target.value)}
        sx={{mr:2}}
      />
      <Button variant="contained" onClick={handleRequest}>Request</Button>
    </Box>
  );
};

export default CalendarPage;
