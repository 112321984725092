// client/src/pages/PlayerGamePage.js
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography } from '@mui/material';

import { AuthContext } from '../context/AuthContext';
import HeroBanner from '../components/games/HeroBanner';
import CalendarAvailability from '../components/games/CalendarAvailability';
import PendingRequestsList from '../components/sessions/PendingRequestsList';
import CharacterEditDialog from '../components/characters/CharacterEditDialog';

const API_BASE = process.env.REACT_APP_API_BASE;

const PlayerGamePage = () => {
  const { token, user, loading } = useContext(AuthContext);
  const { gameId } = useParams();

  const [error, setError] = useState(null);

  // Basic game info
  const [gameName, setGameName] = useState('');
  const [gameImage, setGameImage] = useState(null);
  const [gameSetting, setGameSetting] = useState('');
  const [gameSoftware, setGameSoftware] = useState('');
  const [dmId, setDmId] = useState(null);

  // Availabilities + requests
  const [availabilities, setAvailabilities] = useState([]);
  const [requestsCountByAvailability, setRequestsCountByAvailability] = useState({});

  // Character editing states
  const [editOpen, setEditOpen] = useState(false);
  // We'll store just the numeric ID in `editCharId`:
  const [editCharId, setEditCharId] = useState(null);

  // ---------------------------------------------------------------------------
  //  Character Editing Logic
  // ---------------------------------------------------------------------------
  const handleEditCharacter = (char) => {
    // 'char' is the entire object from HeroBanner.
    // We only need the numeric ID to pass to the dialog:
    if (!char || !char.characterId) return;
    setEditCharId(char.characterId);
    setEditOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditOpen(false);
    setEditCharId(null);
  };

  const handleCharacterUpdated = () => {
    console.log('Character updated; re-fetching the banner or game data...');
    // If needed, re-fetch game details or re-fetch just the characters
    fetchGameDetails();
    handleCloseEditDialog();
  };

  // ---------------------------------------------------------------------------
  //  Fetch Game Details
  // ---------------------------------------------------------------------------
  const fetchGameDetails = useCallback(async () => {
    if (!token || !user) return;
    try {
      const res = await axios.get(`${API_BASE}/games/${gameId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const game = res.data;

      setDmId(game.dungeonMasterId);
      setGameName(game.gameName);
      setGameImage(game.image);
      setGameSetting(game.gameSetting || 'N/A');
      setGameSoftware(game.gamingSoftware || 'N/A');

      // Check if this user is in the game
      const playerGamesRes = await axios.get(`${API_BASE}/games/user/${user.userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const playerGames = playerGamesRes.data;
      const isInGame = playerGames.some(g => g.Game && g.Game.gameId === parseInt(gameId, 10));
      if (!isInGame) {
        setError('You are not invited to this game.');
      }
    } catch (err) {
      console.error('Error fetching game details:', err);
      setError('Failed to load game details.');
    }
  }, [token, user, gameId]);

  // ---------------------------------------------------------------------------
  //  Fetch DM Availabilities
  // ---------------------------------------------------------------------------
  const fetchDMAvailabilities = useCallback(async () => {
    if (!token || !user || !dmId) return;
    try {
      // DM's availability
      const res = await axios.get(`${API_BASE}/dm/availability?dmId=${dmId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAvailabilities(res.data);

      // Also fetch pending requests for this game => show #requests
      const reqRes = await axios.get(`${API_BASE}/sessions/requests?status=pending&gameId=${gameId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const requests = reqRes.data;
      const countMap = {};
      requests.forEach(r => {
        countMap[r.availabilityId] = (countMap[r.availabilityId] || 0) + 1;
      });
      setRequestsCountByAvailability(countMap);

    } catch (err) {
      console.error('Error fetching DM availabilities/requests:', err);
    }
  }, [token, user, dmId, gameId]);

  // ---------------------------------------------------------------------------
  //  useEffects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (!loading && user && token) {
      fetchGameDetails();
    }
  }, [loading, user, token, fetchGameDetails]);

  useEffect(() => {
    if (dmId && !error) {
      fetchDMAvailabilities();
    }
  }, [dmId, error, fetchDMAvailabilities]);

  // ---------------------------------------------------------------------------
  //  Render
  // ---------------------------------------------------------------------------
  if (loading) return <Typography>Loading...</Typography>;
  if (!user) return <Typography>Please login first.</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const coverImage = gameImage
    ? `/images/games/${gameImage}`
    : '/images/games/default-cover.jpg';

  return (
    <Box sx={{ p: 0 }}>
      <HeroBanner
        image={coverImage}
        title={gameName}
        subtitle={`Setting: ${gameSetting} | Software: ${gameSoftware}`}
        height={350}
        overlayColor="rgba(0,0,0,0.5)"
        gameId={gameId}
        token={token}
        isDM={false}
        currentUserId={user.userId}
        // Called when user clicks pencil on their own avatar
        onEditCharacter={handleEditCharacter}
      />

      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          {gameName} - DM Availability
        </Typography>

        <CalendarAvailability
          token={token}
          availabilities={availabilities}
          fetchAvailabilities={fetchDMAvailabilities}
          userRole="player"
          gameId={gameId}
          gameName={gameName}
          requestsCountByAvailability={requestsCountByAvailability}
          onRequestCreated={() => {
            console.log('Session request created by player!');
            fetchDMAvailabilities();
          }}
        />

        <Box sx={{ mt: 4 }}>
          <PendingRequestsList gameId={gameId} />
        </Box>
      </Box>

      {/* Character Edit Dialog */}
      {editCharId && (
        <CharacterEditDialog
          open={editOpen}
          onClose={handleCloseEditDialog}
          characterId={editCharId}    // numeric ID
          token={token}               // pass token so it can auth
          onUpdated={handleCharacterUpdated}
        />
      )}
    </Box>
  );
};

export default PlayerGamePage;
