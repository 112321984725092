// client/src/components/characters/CharacterEditDialog.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Slider,
  IconButton,
  Tooltip
} from '@mui/material';
import Cropper from 'react-easy-crop';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import PropTypes from 'prop-types';
import { getCroppedImg } from './cropUtils';
import {
  getCharacter,
  updateCharacter,
  uploadCharacterAvatar
} from '../../utils/api';

const CharacterEditDialog = ({
  open,
  onClose,
  characterId,
  token,
  onUpdated
}) => {
  const [loading, setLoading] = useState(false);
  const [charData, setCharData] = useState(null);

  // Character fields
  const [race, setRace] = useState('');
  const [age, setAge] = useState('');
  const [bioDescription, setBioDescription] = useState('');
  const [visualDescription, setVisualDescription] = useState('');

  // Avatar editing states
  const [editAvatarMode, setEditAvatarMode] = useState(false);
  const [avatarFileURL, setAvatarFileURL] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState(null);

  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // For hidden file input
  const fileInputRef = useRef(null);

  // ─────────────────────────────────────────────────────────
  //  Fetch Character data
  // ─────────────────────────────────────────────────────────
  const fetchCharacterData = useCallback(async () => {
    if (!open || !characterId || !token) return;
    setLoading(true);
    try {
      const data = await getCharacter(token, characterId);
      setCharData(data);
      setRace(data.race || '');
      setAge(data.age != null ? String(data.age) : '');
      setBioDescription(data.bioDescription || '');
      setVisualDescription(data.visualDescription || '');
    } catch (err) {
      console.error('Error fetching character:', err);
    }
    setLoading(false);
  }, [open, characterId, token]);

  useEffect(() => {
    if (open) {
      fetchCharacterData();
      // Reset avatar editing states
      setEditAvatarMode(false);
      setAvatarFileURL(null);
      setCroppedImageFile(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
    }
  }, [open, fetchCharacterData]);

  // ─────────────────────────────────────────────────────────
  //  Basic fields save
  // ─────────────────────────────────────────────────────────
  const handleSaveCharacter = async () => {
    if (!characterId) return;
    try {
      const body = {
        race,
        age: age ? parseInt(age, 10) : null,
        bioDescription,
        visualDescription
      };
      await updateCharacter(token, characterId, body);
      console.log('Character updated!');
      onUpdated?.();
      onClose();
    } catch (err) {
      console.error('Error updating character:', err);
    }
  };

  // ─────────────────────────────────────────────────────────
  //  Avatar editing logic
  // ─────────────────────────────────────────────────────────
  const onCropComplete = useCallback((_, croppedAreaPx) => {
    setCroppedAreaPixels(croppedAreaPx);
  }, []);

  // If user chooses a file
  const handleSelectFile = (file) => {
    if (!file) return;
    const url = URL.createObjectURL(file);
    setAvatarFileURL(url);
    setCroppedImageFile(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  // Crop image => produce `croppedImageFile`
  const handleCropAndSave = async () => {
    if (!avatarFileURL || !croppedAreaPixels) return;
    try {
      const croppedFile = await getCroppedImg(avatarFileURL, croppedAreaPixels);
      setCroppedImageFile(croppedFile);
    } catch (err) {
      console.error('Cropping error:', err);
    }
  };

  // Upload final cropped image
  const handleUploadAvatar = async () => {
    if (!croppedImageFile) return;
    try {
      await uploadCharacterAvatar(token, characterId, croppedImageFile);
      console.log('Avatar uploaded!');
      await fetchCharacterData();
      // done, close the "avatar edit mode"
      setEditAvatarMode(false);
      setAvatarFileURL(null);
      setCroppedImageFile(null);
    } catch (err) {
      console.error('Error uploading avatar:', err);
    }
  };

  // Click the hidden file input
  const onClickUploadIcon = () => fileInputRef.current?.click();
  const onFileInputChange = (e) => {
    if (e.target.files?.[0]) {
      handleSelectFile(e.target.files[0]);
    }
  };

  // If we don't have data
  if (!open) return null;
  if (!charData && loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Character</DialogTitle>
        <DialogContent><Typography>Loading...</Typography></DialogContent>
      </Dialog>
    );
  }
  if (!charData) {
    return null;
  }

  // ─────────────────────────────────────────────────────────
  //  RENDER
  // ─────────────────────────────────────────────────────────
  const currentAvatarUrl = charData.avatar
    ? `/images/characters/${charData.avatar}`
    : '/images/characters/default.png';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Character</DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        {/* Show the top avatar + edit icon */}
        <Box sx={{ position:'relative', textAlign:'center', mb:2 }}>
          <Box
            component="img"
            src={currentAvatarUrl}
            alt="Current Avatar"
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              border: '2px solid #ccc',
              objectFit:'cover',
              display: 'block',
              mx: 'auto'
            }}
          />
          {/* If not in "edit avatar" mode, show an icon to enable it */}
          {!editAvatarMode && (
            <Tooltip title="Edit Avatar">
              <IconButton
                size="small"
                onClick={() => setEditAvatarMode(true)}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 'calc(50% - 60px)' // something to put near the image center
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        {/* Show "edit avatar" zone if user clicked the edit icon */}
        {editAvatarMode && (
          <Box sx={{ mb:2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Update Avatar
            </Typography>

            {/* If no file chosen -> show upload icon */}
            {!avatarFileURL && !croppedImageFile && (
              <Box sx={{
                p:2, border:'1px dashed #999', textAlign:'center', mb:2
              }}>
                <Tooltip title="Select file">
                  <IconButton onClick={onClickUploadIcon}>
                    <CloudUploadIcon />
                  </IconButton>
                </Tooltip>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={onFileInputChange}
                  style={{ display: 'none' }}
                />
              </Box>
            )}

            {/* If a file is chosen but not cropped => show cropper */}
            {avatarFileURL && !croppedImageFile && (
              <>
                <Box sx={{ position:'relative', width:'100%', height:300, mb:2 }}>
                  <Cropper
                    image={avatarFileURL}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Box>
                <Typography>Zoom</Typography>
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e, val) => setZoom(val)}
                  sx={{ width:200, mb:1 }}
                />
                <Button variant="contained" onClick={handleCropAndSave}>
                  Crop &amp; Save
                </Button>
                <Button sx={{ ml:2 }} onClick={() => {
                  // Cancel cropping
                  setAvatarFileURL(null);
                }}>
                  Cancel
                </Button>
              </>
            )}

            {/* If we have a croppedImageFile => preview & upload */}
            {croppedImageFile && (
              <Box>
                <Typography variant="body1">Cropped Preview:</Typography>
                <Box
                  component="img"
                  src={URL.createObjectURL(croppedImageFile)}
                  alt="avatar preview"
                  sx={{ width:120, height:120, borderRadius:'50%', my:1 }}
                />
                <Button variant="contained" onClick={handleUploadAvatar}>
                  Upload Cropped Avatar
                </Button>
                <Button sx={{ ml:2 }} onClick={() => {
                  // Cancel => revert to no file
                  setAvatarFileURL(null);
                  setCroppedImageFile(null);
                }}>
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        )}

        {/* Then the rest of the text fields */}
        <TextField
          label="Race"
          value={race}
          onChange={e => setRace(e.target.value)}
          fullWidth
          sx={{ mb:2 }}
        />
        <TextField
          label="Age"
          type="number"
          value={age}
          onChange={e => setAge(e.target.value)}
          fullWidth
          sx={{ mb:2 }}
        />
        <TextField
          label="Visual Description"
          value={visualDescription}
          onChange={e => setVisualDescription(e.target.value)}
          multiline
          rows={2}
          fullWidth
          sx={{ mb:2 }}
        />
        <TextField
          label="Bio Description"
          value={bioDescription}
          onChange={e => setBioDescription(e.target.value)}
          multiline
          rows={3}
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" onClick={handleSaveCharacter}>
          Save Character
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CharacterEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  characterId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  onUpdated: PropTypes.func
};

export default CharacterEditDialog;
