import React from 'react';
import { Typography, Box } from '@mui/material';

const FavoritesList = ({ favorites }) => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <Typography variant="h5">Favorites</Typography>
      <Typography variant="h6" sx={{ mt:2 }}>Favorite Locations</Typography>
      {favorites.locations && favorites.locations.length > 0 ? (
        favorites.locations.map(loc => (
          <Box key={loc.locationId} sx={{ border:'1px solid #ccc', p:2, my:2 }}>
            <Typography variant="body1">Location: {loc.locationName}</Typography>
            <Typography variant="body2">Reason: {loc.reason_for_coming || 'N/A'}</Typography>
          </Box>
        ))
      ) : (
        <Typography>No favorite locations.</Typography>
      )}

      <Typography variant="h6" sx={{ mt:2 }}>Favorite Scenes</Typography>
      {favorites.scenes && favorites.scenes.length > 0 ? (
        favorites.scenes.map(scene => (
          <Box key={scene.sceneId} sx={{ border:'1px solid #ccc', p:2, my:2 }}>
            <Typography variant="body1">Scene: {scene.summary || 'No summary'}</Typography>
            <Typography variant="body2">Reason: {scene.reason_for_coming || 'N/A'}</Typography>
          </Box>
        ))
      ) : (
        <Typography>No favorite scenes.</Typography>
      )}
    </div>
  );
};

export default FavoritesList;
