// Sidebar.js
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { Home, Person, SportsEsports } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 200; // Set the width of the sidebar

const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#243447', // dark background
          color: '#fff'
        },
      }}
    >
      <Toolbar /> {/* This ensures top space if you have an AppBar above */}
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon><Home style={{ color: '#fff' }}/></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/account">
          <ListItemIcon><Person style={{ color: '#fff' }}/></ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
        <ListItem button component={Link} to="/dm">
          <ListItemIcon><SportsEsports style={{ color: '#fff' }}/></ListItemIcon>
          <ListItemText primary="DM Dashboard" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
