// client/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Toolbar } from '@mui/material';
import theme from './theme';

import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import DMRoute from './components/DMRoute'; // DM-only route

import Home from './pages/Home';
import Login from './pages/Login';
import Account from './pages/Account';
import CalendarPage from './pages/Calendar';
import DMPage from './pages/DMPage'; // DM main dashboard
import DMGamePage from './pages/DMGamePage'; // Game management page
import SessionPage from './pages/SessionPage'; // Session management page
import PlayerDashboard from './pages/PlayerDashboard'; // Player dashboard page
import PlayerGamePage from './pages/PlayerGamePage'; // Player game page

import './index.css'; // Make sure index.css is in src/ folder

const drawerWidth = 200; // width of Sidebar

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          {/* Wrap the layout in a Box with display:flex to have sidebar on the left and content on the right */}
          <Box sx={{ display: 'flex' }}>
            <Sidebar />

            {/* Main content area */}
            <Box 
              component="main"
              sx={{
                flexGrow: 1,                
                //ml: `${drawerWidth}px`, // add left margin to prevent overlap by sidebar
              }}
            >
              {/* If you had a top navbar (AppBar), add a Toolbar here to push content down */}
              <Toolbar />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route 
                  path="/account" 
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="/calendar" 
                  element={
                    <ProtectedRoute>
                      <CalendarPage />
                    </ProtectedRoute>
                  }
                />

                {/* DM dashboard */}
                <Route
                  path="/dm"
                  element={
                    <ProtectedRoute>
                      <DMRoute>
                        <DMPage />
                      </DMRoute>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/dm/game/:gameId"
                  element={
                    <ProtectedRoute>
                      <DMRoute>
                        <DMGamePage />
                      </DMRoute>
                    </ProtectedRoute>
                  }
                />

                <Route 
                  path="/game/:gameId/session/:sessionId"
                  element={
                    <ProtectedRoute>
                      <DMRoute>
                        <SessionPage />
                      </DMRoute>
                    </ProtectedRoute>
                  }
                />

                {/* Player dashboard */}
                <Route
                  path="/player"
                  element={
                    <ProtectedRoute>
                      <PlayerDashboard />
                    </ProtectedRoute>
                  }
                />

                {/* Player game page */}
                <Route
                  path="/player/game/:gameId"
                  element={
                    <ProtectedRoute>
                      <PlayerGamePage />
                    </ProtectedRoute>
                  }
                />
              </Routes>

              <Footer />
            </Box>
          </Box>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
