// client/src/components/sessions/SessionOverview.js
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { Box, Typography, Grid } from '@mui/material';
import SessionCard from './SessionCard';

const API_BASE = process.env.REACT_APP_API_BASE;

const SessionOverview = () => {
  const { token } = useContext(AuthContext);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchPlannedSessions = async () => {
      if (!token) return;
      try {
        const res = await axios.get(`${API_BASE}/sessions/planned`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // 1) Sort by startTime ascending
        const sorted = [...res.data].sort((a, b) => {
          const aTime = new Date(a.startTime).getTime() || 0;
          const bTime = new Date(b.startTime).getTime() || 0;
          return aTime - bTime;
        });

        // 2) Slice to only 3
        const sliced = sorted.slice(0, 3);

        setSessions(sliced);
      } catch (err) {
        console.error('Error fetching planned sessions:', err);
      }
    };
    fetchPlannedSessions();
  }, [token]);

  if (sessions.length === 0) {
    return <Typography>No planned sessions.</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Planned Sessions
      </Typography>
      <Grid container spacing={2}>
        {/* 3) 3-column layout at MD (md=4 => 12/4=3) */}
        {sessions.map((s) => (
          <Grid item key={s.sessionId} xs={12} sm={6} md={4}>
            <SessionCard session={s} mode="planned" />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SessionOverview;
