// client/src/components/games/CalendarAvailability.js
import React, { useState, useMemo } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';

// React Big Calendar default styles
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Your custom overrides
import './CalendarAvailability.css';

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import axios from 'axios';

const localizer = dayjsLocalizer(dayjs);

const CalendarAvailability = ({
  token,
  availabilities,
  fetchAvailabilities,
  userRole,
  onRequestCreated,
  gameId,
  gameName,
  requestsCountByAvailability = {}
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [slotInfo, setSlotInfo] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);

  const [minHours, setMinHours] = useState('');
  const [maxHours, setMaxHours] = useState('');
  const [playerRequestMode, setPlayerRequestMode] = useState(false);

  const API_BASE = process.env.REACT_APP_API_BASE;

  // Prepare event objects for react-big-calendar
  const events = useMemo(() => {
    return availabilities.map((a) => {
      const start = new Date(a.startTime);
      const end = new Date(a.endTime);
      const reqCount = requestsCountByAvailability[a.availabilityId] || 0;

      let title = 'DM Slot';
      let className = 'dm-slot ';
      if (userRole === 'dungeon_master') {
        title = `DM Slot (${a.minDuration / 60}h - ${a.maxDuration / 60}h)`;
      }
      if (a.used) {
        className += 'dm-slot-booked';
        title += ' (booked)';
      }
      if (reqCount > 0) {
        className += 'dm-slot-pending';
        title += ` (${reqCount} requests pending)`;
      }

      return {
        id: a.availabilityId,
        title,
        start,
        end,
        allDay: false,
        minDuration: a.minDuration,
        maxDuration: a.maxDuration,
        rawData: a,
        className
      };
    });
  }, [availabilities, userRole, requestsCountByAvailability]);

  // Example: define a "scrollToTime" date at 10:00 AM:
  const scrollToTime = new Date();
  scrollToTime.setHours(10, 0, 0, 0); // 10:00 AM

  // --------------------------------------------
  // Handling slot selection (empty area)
  // --------------------------------------------
  const handleSelectSlot = (slot) => {
    if (userRole === 'dungeon_master') {
      // DM creating or editing a new slot
      setEditingEvent(null);
      setSlotInfo(slot);

      const durationHours = (slot.end - slot.start) / (1000 * 60 * 60);
      const roundedHours = Math.round(durationHours);

      setMinHours(String(roundedHours));
      setMaxHours(String(roundedHours));
      setPlayerRequestMode(false);
      setOpenDialog(true);
    } else {
      // Player choosing a sub-range to request a session
      const chosenStart = slot.start;
      const chosenEnd = slot.end;

      // Must fall fully within an existing event
      const containingEvent = events.find(
        (e) => chosenStart >= e.start && chosenEnd <= e.end
      );
      if (!containingEvent) {
        console.log(
          'Selected timeslot does not fall fully within any DM availability slot.'
        );
        return;
      }

      const duration = (chosenEnd - chosenStart) / (1000 * 60);
      if (
        duration < containingEvent.minDuration ||
        duration > containingEvent.maxDuration
      ) {
        console.log('Selected duration not within min/max constraints.');
        return;
      }

      setSlotInfo({ start: chosenStart, end: chosenEnd });
      setEditingEvent(containingEvent);
      setPlayerRequestMode(true);
      setOpenDialog(true);
    }
  };

  // --------------------------------------------
  // Handling event selection (existing event)
  // --------------------------------------------
  const handleSelectEvent = (event) => {
    if (userRole === 'dungeon_master') {
      // DM editing an availability
      setSlotInfo({ start: event.start, end: event.end });
      setMinHours(String(event.minDuration / 60));
      setMaxHours(String(event.maxDuration / 60));
      setEditingEvent(event);
      setPlayerRequestMode(false);
      setOpenDialog(true);
    } else if (userRole === 'player') {
      // Player requesting a session
      const chosenStart = event.start;
      const chosenEnd = event.end;
      const duration = (chosenEnd - chosenStart) / (1000 * 60);

      if (duration < event.minDuration || duration > event.maxDuration) {
        console.log('Duration not within min/max constraints.');
        return;
      }

      setSlotInfo({ start: chosenStart, end: chosenEnd });
      setEditingEvent(event);
      setPlayerRequestMode(true);
      setOpenDialog(true);
    } else {
      console.log('Viewing DM slot:', event);
    }
  };

  // --------------------------------------------
  // Closing the dialog
  // --------------------------------------------
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSlotInfo(null);
    setEditingEvent(null);
    setMinHours('');
    setMaxHours('');
    setPlayerRequestMode(false);
  };

  // --------------------------------------------
  // DM: create or update availability
  // --------------------------------------------
  const handleCreateOrUpdateAvailability = async () => {
    if (!slotInfo) return;
    const startTime = slotInfo.start.toISOString();
    const endTime = slotInfo.end.toISOString();
    const minDuration = parseInt(minHours, 10) * 60;
    const maxDuration = parseInt(maxHours, 10) * 60;

    try {
      if (editingEvent) {
        // Update existing
        await axios.put(
          `${API_BASE}/dm/availability/${editingEvent.id}`,
          {
            startTime,
            endTime,
            minDuration,
            maxDuration
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        console.log('Availability slot updated!');
      } else {
        // Create new
        await axios.post(
          `${API_BASE}/dm/availability`,
          {
            startTime,
            endTime,
            minDuration,
            maxDuration
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        console.log('Availability slot created!');
      }

      fetchAvailabilities();
    } catch (err) {
      console.error('Error creating/updating availability:', err);
    }
    handleCloseDialog();
  };

  const handleDeleteAvailability = async () => {
    if (!editingEvent) return;
    try {
      await axios.delete(
        `${API_BASE}/dm/availability/${editingEvent.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('Slot deleted!');
      fetchAvailabilities();
    } catch (err) {
      console.error('Error deleting slot:', err);
    }
    handleCloseDialog();
  };

  // --------------------------------------------
  // Player: request a session
  // --------------------------------------------
  const handleRequestSession = async () => {
    if (!editingEvent || !slotInfo || !gameId) return;
    try {
      await axios.post(
        `${API_BASE}/sessions/request`,
        {
          availabilityId: editingEvent.id,
          requestedStartTime: slotInfo.start.toISOString(),
          requestedEndTime: slotInfo.end.toISOString(),
          gameId: parseInt(gameId, 10)
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      console.log('Session request created!');
      if (onRequestCreated) onRequestCreated();

      // Re-fetch availabilities to show updated request counts
      fetchAvailabilities();
    } catch (err) {
      console.error('Error creating session request:', err);
    }
    handleCloseDialog();
  };

  return (
    <Box>
      <div className="dnd-dark-calendar">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          style={{ height: 600, margin: '20px 0' }}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          defaultView="week"
          views={['week', 'day', 'agenda']}
          scrollToTime={scrollToTime} 
          eventPropGetter={(event) => {           
            return { className: event.className };
          }}
        />
      </div>

      {/* Dialog for availability creation/updating or requesting session */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {playerRequestMode
            ? 'Request Session'
            : editingEvent
            ? 'Edit Availability Slot'
            : 'Create Availability Slot'}
        </DialogTitle>
        <DialogContent>
          {slotInfo && (
            <p>
              Selected Time Slot:
              <br />
              Start: {slotInfo.start.toLocaleString()}
              <br />
              End: {slotInfo.end.toLocaleString()}
            </p>
          )}

          {playerRequestMode ? (
            <p>Do you want to reserve this slot for a session of {gameName}?</p>
          ) : (
            <>
              <TextField
                label="Min Duration (hours)"
                value={minHours}
                onChange={(e) => setMinHours(e.target.value)}
                type="number"
                sx={{ display: 'block', my: 2 }}
              />
              <TextField
                label="Max Duration (hours)"
                value={maxHours}
                onChange={(e) => setMaxHours(e.target.value)}
                type="number"
                sx={{ display: 'block', my: 2 }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {playerRequestMode ? (
            <>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button variant="contained" onClick={handleRequestSession}>
                Request
              </Button>
            </>
          ) : (
            <>
              {editingEvent && (
                <Button color="error" onClick={handleDeleteAvailability}>
                  Delete
                </Button>
              )}
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button variant="contained" onClick={handleCreateOrUpdateAvailability}>
                {editingEvent ? 'Update' : 'Create'}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CalendarAvailability;
