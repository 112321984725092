import React from 'react';
import { Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CreateGameForm = ({ newGame, setNewGame, onCreate }) => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <Typography variant="h5" gutterBottom>Create New Game</Typography>
      <TextField
        label="Game Name"
        value={newGame.gameName}
        onChange={e => setNewGame({ ...newGame, gameName: e.target.value })}
        sx={{mr:2}}
      />
      <TextField
        label="Game Setting"
        value={newGame.gameSetting}
        onChange={e => setNewGame({ ...newGame, gameSetting: e.target.value })}
        sx={{mr:2}}
      />
      <FormControl sx={{mr:2, minWidth:120}}>
        <InputLabel id="software-label">Software</InputLabel>
        <Select
          labelId="software-label"
          value={newGame.gamingSoftware}
          label="Software"
          onChange={(e) => setNewGame({ ...newGame, gamingSoftware: e.target.value })}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value="foundry">Foundry</MenuItem>
          <MenuItem value="roll20">Roll20</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" onClick={onCreate}>Create</Button>
    </div>
  );
};

export default CreateGameForm;
