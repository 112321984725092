// client/src/pages/SessionPage.js
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  getSessionDetails,
  getAllCharactersInGame,
} from '../utils/api'; 
import axios from 'axios';

import LocationForm from '../components/locations/LocationForm';
import LocationList from '../components/locations/LocationList';

const API_BASE = process.env.REACT_APP_API_BASE;

const SessionPage = () => {
  const { token, user } = useContext(AuthContext);
  const { sessionId } = useParams();

  const [locations, setLocations] = useState([]);
  const [newLocationName, setNewLocationName] = useState('');
  const [newLocationReason, setNewLocationReason] = useState('');

  const [expandedLocations, setExpandedLocations] = useState({});
  const [scenes, setScenes] = useState({});

  // For hover: we need the correct gameId from the session
  const [gameId, setGameId] = useState(null);
  const [allCharacters, setAllCharacters] = useState({});

  // 1) Fetch session details to get the actual gameId
  const fetchSessionDetails = useCallback(async () => {
    try {
      const sessionData = await getSessionDetails(token, sessionId);
      // Suppose sessionData = { sessionId, gameId, ... }
      setGameId(sessionData.gameId);
    } catch (err) {
      console.error('Error fetching session details:', err);
    }
  }, [token, sessionId]);

  // 2) Fetch all characters for the game
  const fetchCharactersForGame = useCallback(async (actualGameId) => {
    try {
      const chars = await getAllCharactersInGame(token, actualGameId);
      const byName = {};
      chars.forEach((c) => {
        if (c.name) {
          byName[c.name] = c;
        }
      });
      setAllCharacters(byName);
    } catch (err) {
      console.error('Error fetching characters for game:', err);
    }
  }, [token]);

  // 3) Fetch all locations for the session
  const fetchLocations = useCallback(async () => {
    try {
      const res = await axios.get(`${API_BASE}/session-locations/${sessionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLocations(res.data);
    } catch (err) {
      console.error('Error fetching locations:', err);
    }
  }, [token, sessionId]);

  // 4) Combined effect
  useEffect(() => {
    if (token && user) {
      fetchSessionDetails()
        .then(() => fetchLocations())
        .catch(err => console.error('Error in session detail or location fetch:', err));
    }
  }, [token, user, sessionId, fetchSessionDetails, fetchLocations]);

  // 5) Once we know the gameId, fetch characters
  useEffect(() => {
    if (gameId && token) {
      fetchCharactersForGame(gameId);
    }
  }, [gameId, token, fetchCharactersForGame]);

  // CREATE / UPDATE / DELETE
  const handleCreateLocation = async () => {
    if (!newLocationName) return console.log('Location name is required');
    try {
      await axios.post(
        `${API_BASE}/session-locations/${sessionId}`,
        {
          locationName: newLocationName,
          reason_for_coming: newLocationReason,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNewLocationName('');
      setNewLocationReason('');
      fetchLocations();
    } catch (err) {
      console.error('Error creating location:', err);
    }
  };

  const onUpdateLocation = async (locationId, data) => {
    try {
      await axios.put(
        `${API_BASE}/session-locations/${locationId}`,
        {
          locationName: data.locationName,
          reason_for_coming: data.reason_for_coming,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchLocations();
    } catch (err) {
      console.error('Error updating location:', err);
    }
  };

  const onDeleteLocation = async (locationId) => {
    if (!window.confirm('Delete this location?')) return;
    try {
      await axios.delete(`${API_BASE}/session-locations/${locationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchLocations();
    } catch (err) {
      console.error('Error deleting location:', err);
    }
  };

  const toggleLocationFavorite = async (locationId, currentFavorite) => {
    try {
      await axios.put(
        `${API_BASE}/session-locations/${locationId}`,
        { favorite: !currentFavorite },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchLocations();
    } catch (err) {
      console.error('Error toggling location favorite:', err);
    }
  };

  // Scenes
  const handleExpandLocation = (locationId) => {
    setExpandedLocations((prev) => ({
      ...prev,
      [locationId]: !prev[locationId],
    }));
    if (!expandedLocations[locationId]) {
      fetchScenes(locationId);
    }
  };

  const fetchScenes = async (locationId) => {
    try {
      const res = await axios.get(`${API_BASE}/session-scenes/${locationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setScenes((prev) => ({ ...prev, [locationId]: res.data }));
    } catch (err) {
      console.error('Error fetching scenes:', err);
    }
  };

  const onCreateScene = async (locationId, data) => {
    try {
      await axios.post(
        `${API_BASE}/session-scenes/${locationId}`,
        {
          summary: data.summary,
          reason_for_coming: data.reason_for_coming,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchScenes(locationId);
    } catch (err) {
      console.error('Error creating scene:', err);
    }
  };

  const onToggleSceneFavorite = async (sceneId, currentFavorite, locationId) => {
    try {
      await axios.put(
        `${API_BASE}/session-scenes/${sceneId}`,
        { favorite: !currentFavorite },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchScenes(locationId);
    } catch (err) {
      console.error('Error toggling scene favorite:', err);
    }
  };

  const onUpdateScene = async (sceneId, data, locationId) => {
    try {
      await axios.put(
        `${API_BASE}/session-scenes/${sceneId}`,
        {
          summary: data.summary,
          reason_for_coming: data.reason_for_coming,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchScenes(locationId);
    } catch (err) {
      console.error('Error updating scene:', err);
    }
  };

  const onDeleteScene = async (sceneId, locationId) => {
    if (!window.confirm('Delete this scene?')) return;
    try {
      await axios.delete(`${API_BASE}/session-scenes/${sceneId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchScenes(locationId);
    } catch (err) {
      console.error('Error deleting scene:', err);
    }
  };

  if (!user) {
    return <Typography>Please login first.</Typography>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Session {sessionId} Management
      </Typography>

      <LocationForm
        newLocationName={newLocationName}
        setNewLocationName={setNewLocationName}
        newLocationReason={newLocationReason}
        setNewLocationReason={setNewLocationReason}
        onCreate={handleCreateLocation}
      />

      <LocationList
        locations={locations}
        expandedLocations={expandedLocations}
        scenes={scenes}
        onExpandLocation={handleExpandLocation}
        onToggleFavoriteLocation={toggleLocationFavorite}
        onFetchScenes={fetchScenes}
        onCreateScene={onCreateScene}
        onToggleSceneFavorite={onToggleSceneFavorite}
        onUpdateScene={onUpdateScene}
        onDeleteScene={onDeleteScene}
        onUpdateLocation={onUpdateLocation}
        onDeleteLocation={onDeleteLocation}
        allCharacters={allCharacters} // For hover popups
      />
    </Box>
  );
};

export default SessionPage;
