// client/src/utils/api.js
import axios from 'axios';

const API_BASE = 'http://localhost:3001/api'; // or wherever your server runs

export const getUser = async (token, userId) => {
  const res = await axios.get(`${API_BASE}/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const updateUser = async (token, userId, data) => {
  const res = await axios.put(`${API_BASE}/users/${userId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

export const getGames = async (token, userId) => {
  const res = await axios.get(`${API_BASE}/games/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

export const requestSession = async (token, userId, requestedDate) => {
  const res = await axios.post(
    `${API_BASE}/sessions/${userId}/request`,
    { date: requestedDate },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data;
};

export const getAllCharactersInGame = async (token, gameId) => {
  const res = await axios.get(`${API_BASE}/characters/all/${gameId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

export const getCharacter = async (token, characterId) => {
  const res = await axios.get(`${API_BASE}/characters/${characterId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

export const updateCharacter = async (token, characterId, data) => {
  const res = await axios.put(`${API_BASE}/characters/${characterId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

/**
 * Upload a cropped avatar to /api/characters/image/:characterId
 * `croppedImageFile` is a File/Blob from the Cropper step.
 */
export const uploadCharacterAvatar = async (token, characterId, croppedImageFile) => {
  const formData = new FormData();
  formData.append('image', croppedImageFile);
  
  // Use axios for consistency
  const res = await axios.post(
    `${API_BASE}/characters/image/${characterId}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return res.data; // The server typically returns { message, avatar } or similar
};

/**
 * POST /games/:gameId/generate-cover
 * @param {string} token - auth token
 * @param {number|string} gameId - the game ID
 * @param {string} prompt - user’s prompt
 * 
 * Backend is expected to combine character descriptions + prompt,
 * use DALL-E or other AI, and return { images: ['url1', 'url2', 'url3'] } or similar
 */
export const generateGameCover = async (token, gameId, prompt) => {
  const res = await axios.post(
    `${API_BASE}/games/${gameId}/generate-cover`,
    { prompt },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data; // e.g. { images: [url, url, url], ... }
};

/**
 * POST /games/:gameId/image
 * @param {string} token - auth token
 * @param {number|string} gameId - the game ID
 * @param {string} imageUrl - e.g. one of the returned AI URLs
 * 
 * The backend downloads that image or processes it, then saves to 'public/images/games'
 * and updates the DB. Returns e.g. { message, image: 'filename.jpg' } 
 */
export const uploadCoverForGame = async (token, gameId, imageUrl) => {
  // Some backends accept formData with a file, or a JSON with { imageUrl }.
  // If your server expects JSON with { imageUrl }, do:
  const body = { imageUrl };

  const res = await axios.post(
    `${API_BASE}/games/${gameId}/image`,
    body,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data; // e.g. { message: 'Cover updated', image: 'somefile.jpg' }
};


export const getSessionDetails = async (token, sessionId) => {
  // Example endpoint: GET /sessions/:sessionId => { sessionId, gameId, ... }
  // Adjust if your backend uses a different URL or returns a different shape
  const res = await axios.get(`${API_BASE}/sessions/${sessionId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data; 
};

