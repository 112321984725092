// client/src/components/sessions/SessionList.js
import React from 'react';
import { Typography, Card, CardContent, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const SessionList = ({ gameId, sessions }) => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <Typography variant="h5" gutterBottom>Sessions</Typography>
      {sessions.length > 0 ? (
        sessions.map(s => (
          <Card key={s.sessionId} sx={{ mb:2 }}>
            <CardContent sx={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}>
              <Box>
                <Typography variant="body1">{s.name}</Typography>
              </Box>
              <Button 
                variant="contained" 
                component={Link} 
                to={`/game/${gameId}/session/${s.sessionId}`}
              >
                Manage Session
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>No sessions yet.</Typography>
      )}
    </div>
  );
};

export default SessionList;
