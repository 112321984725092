// client/src/components/characters/CharacterHoverCard.js

import React, { useState, useRef } from 'react';
import { Box, Avatar, Typography, Popper } from '@mui/material';

const CharacterHoverCard = ({ characterName, characterData, children }) => {
  // Is the card currently open?
  const [open, setOpen] = useState(false);

  // We store reference to the text <span>
  const anchorRef = useRef(null);

  // Timer so we can delay closing (avoid flicker if user goes to the popper)
  let closeTimer = useRef(null);

  // Handle mouse enter on the name
  const handleMouseEnterName = () => {
    // Cancel any pending close
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
    // Show popper
    setOpen(true);
  };

  // Handle mouse leave on the name
  const handleMouseLeaveName = () => {
    // Start a small timer to close
    closeTimer.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  // If we enter the popper, cancel the timer
  const handleMouseEnterPopper = () => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
  };

  // If we leave the popper, close quickly
  const handleMouseLeavePopper = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        ref={anchorRef}
        onMouseEnter={handleMouseEnterName}
        onMouseLeave={handleMouseLeaveName}
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
          color: 'inherit'
        }}
      >
        {children /* the matched name text */}
      </span>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        // place above or below as you like:
        placement="top-start"
        disablePortal={true} // stay in DOM flow, no overlay
      >
        {/* We wrap content so we can track mouse enters/leaves */}
        <Box
          onMouseEnter={handleMouseEnterPopper}
          onMouseLeave={handleMouseLeavePopper}
          sx={{
            p: 2,
            width: 200,
            border: '1px solid #ccc',
            borderRadius: 1,
            bgcolor: 'background.paper',
            boxShadow: 3,
            mt: 1 // margin above anchor
          }}
        >
          {characterData ? (
            <>
              <Avatar
                src={
                  characterData.avatarrac
                    ? `/images/characters/${characterData.avatar}`
                    : '/images/characters/default.png'
                }
                alt={characterName}
                sx={{ width: 64, height: 64, mb: 1 }}
              />
              <Typography variant="subtitle1" fontWeight="bold">
                {characterData.name}
              </Typography>
              <Typography variant="body2">
                Race: {characterData.race}
              </Typography>
              <Typography variant="body2">
                Player: {characterData.User.realName}
              </Typography>
              {/* other fields if needed */}
            </>
          ) : (
            <Typography>Character not found.</Typography>
          )}
        </Box>
      </Popper>
    </>
  );
};

export default CharacterHoverCard;
